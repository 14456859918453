<template>
  <v-theme-provider :dark="dark">
    <div>
      <!--<base-img-->
        <!--:src="require('@/assets/zero-logo-dark.svg')"-->
        <!--contain-->
        <!--max-width="128"-->
        <!--min-height="78"-->
        <!--width="100%"-->
      <!--/>-->
      <base-subheading title="OlapDB" />

      <base-title
        size="body-1"
        space="4"
        :title="$t('olapdbTitle')"
        weight="regular"
      />

      <base-body>
        {{$t('olapdbIntro')}}
      </base-body>

      <base-btn
        class="mb-12"
        color="white"
        outlined
        to = "Home"
      >
        {{$t('moreInfo')}}
      </base-btn>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfo',
    props: {
      title: String,
      dark: Boolean,
    },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: '8553 N. Beach St. Ste. 227<br>Fort Worth, Texas 76137',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '01 (800) 433 744<br>01 (800) 433 633',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'john@vuetifyjs.com<br>heather@vuetifyjs.com',
        },
      ],
    }),
  }
</script>
