<template>
  <pre><slot></slot></pre>
</template>

<script>
  export default {
    name: 'BaseCode',
  }
</script>

<style scoped>
  pre {
    background-color: #e0e0e0;
    padding: 5px;
    border-radius: 5px;
    overflow-x: auto;
    width: 100%;
    word-wrap: break-word;
    font-family: "Courier New";
  }
</style>
