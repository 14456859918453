// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: 'user-manual',
          name: 'User Manual',
          beforeEnter () {
            location.href = '/docs/user-manual.html'
          },
        },
        {
          path: 'developer-api',
          name: 'Developer API',
          beforeEnter () {
            location.href = '/docs/index.html'
          },
        },
        {
          path: 'download',
          name: 'Download',
          component: () => import('@/views/download/Index.vue'),
          meta: { src: require('@/assets/about.jpg') },
        },
        {
          path: 'license',
          name: 'License',
          component: () => import('@/views/purchase/Index.vue'),
          meta: { src: require('@/assets/about.jpg') },
        },
        {
          path: 'about',
          name: 'About',
          component: () => import('@/views/about/Index.vue'),
          meta: { src: require('@/assets/about.jpg') },
        },
        {
          path: 'contact-us',
          name: 'Contact Us',
          component: () => import('@/views/contact-us/Index.vue'),
          meta: { src: require('@/assets/contact.jpg') },
        },
        {
          path: 'language',
          name: 'English',
          beforeEnter () {
          },
        },

        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/home/Index.vue'),
        },

      ],
    },

    {
      path: 'https://github.com/nvwaonline/olapdb',
      name: 'Github',
      beforeEnter () {
        location.href = 'https://github.com/nvwaonline/olapdb'
        // window.open('https://github.com/nvwaonline/olapdb', '_blank')
      },
    },

  ],
})

export default router
