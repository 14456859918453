<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />

    <v-text-field
      v-model="olapdbOwner"
      :label="$t('licenseOwner')"
      outlined
      dense
      :placeholder="$t('licenseOwner')"
      :hint="$t('licenseHint')"
    ></v-text-field>

    <v-text-field
      v-model="olapdbIdentify"
      :label="$t('licenseIdentify')"
      outlined
      dense
      :placeholder="$t('licenseIdentify')"
      :hint="$t('licenseHint')"
    ></v-text-field>

    <!--<v-text-field-->
      <!--v-model="licenseTaskCount"-->
      <!--:label="$t('licenseTaskCount')"-->
      <!--outlined-->
      <!--dense-->
      <!--:placeholder="$t('licenseHintTask')"-->
      <!--:hint="$t('licenseHintTask')"-->
    <!--&gt;</v-text-field>-->
    <v-select
      :items="getTasks"
      v-model="licenseTaskCount"
      :label="$t('licenseTaskCount')"
      outlined
      dense
      :placeholder="$t('licenseHintTask')"
      :hint="$t('licenseHintTask')"
    ></v-select>

    <v-select
      :items="getPeriods"
      v-model="licensePeriod"
      :label="$t('purchasePeriod')"
      outlined
      dense
    ></v-select>

    <base-btn
      :color="!theme.isDark ? 'accent' : 'white'"
      outlined
      target="_blank"
      @click="applyOfficialLicense"
    >
      {{$t('purchaseButton')}}
    </base-btn>
  </div>
</template>

<script>
  export default {
    name: 'BasePurchaseLicenseForm',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    props: {
      olapdbOwner: String,
      olapdbIdentify: String,
      contactEmail: String,
      subtitle: String,
      title: {
        type: String,
        default: 'MAIL US YOUR MESSAGE',
      },
    },

    data () {
      return {
        licenseTaskCount: Number,
        licensePeriod: Number,

        productCodes: {
          '100_TASK_5_YEAR': '40863004',
          '200_TASK_4_YEAR': '40863028',
          '50_TASK_3_YEAR': '40863036',
          '10000_TASK_2_YEAR': '40862960',
          '10_TASK_3_YEAR': '40855854',
          '1000_TASK_4_YEAR': '40863015',
          '2000_TASK_2_YEAR': '40862932',
          '200_TASK_3_YEAR': '40863027',
          '5000_TASK_1_YEAR': '40862933',
          '5000_TASK_5_YEAR': '40863009',
          '10_TASK_4_YEAR': '40862982',
          '20_TASK_1_YEAR': '40862920',
          '500_TASK_2_YEAR': '40862956',
          '10000_TASK_1_YEAR': '40862935',
          '10000_TASK_4_YEAR': '40863019',
          '5000_TASK_2_YEAR': '40862959',
          '2000_TASK_3_YEAR': '40863033',
          '500_TASK_5_YEAR': '40863006',
          '5000_TASK_4_YEAR': '40863048',
          '100_TASK_3_YEAR': '40863011',
          '1000_TASK_3_YEAR': '40863014',
          '20_TASK_3_YEAR': '40862997',
          '200_TASK_1_YEAR': '40862927',
          '5000_TASK_3_YEAR': '40863047',
          '10000_TASK_5_YEAR': '40863010',
          '100_TASK_2_YEAR': '40862953',
          '1000_TASK_1_YEAR': '40862929',
          '500_TASK_3_YEAR': '40863042',
          '2000_TASK_5_YEAR': '40863008',
          '50_TASK_1_YEAR': '40862923',
          '20_TASK_4_YEAR': '40862998',
          '50_TASK_5_YEAR': '40862999',
          '10_TASK_1_YEAR': '40862524',
          '1000_TASK_5_YEAR': '40863007',
          '1000_TASK_2_YEAR': '40862957',
          '50_TASK_2_YEAR': '40862951',
          '100_TASK_1_YEAR': '40862926',
          '20_TASK_5_YEAR': '40862996',
          '20_TASK_2_YEAR': '40862950',
          '10_TASK_5_YEAR': '40862994',
          '100_TASK_4_YEAR': '40863012',
          '50_TASK_4_YEAR': '40863038',
          '500_TASK_1_YEAR': '40862928',
          '500_TASK_4_YEAR': '40863039',
          '10_TASK_2_YEAR': '40866449',
          '200_TASK_2_YEAR': '40862954',
          '2000_TASK_1_YEAR': '40862930',
          '2000_TASK_4_YEAR': '40863034',
          '10000_TASK_3_YEAR': '40863018',
          '200_TASK_5_YEAR': '40863005',
        },
      }
    },

    methods: {
      applyOfficialLicense () {
        this.dialog = false
        console.log('applyOfficialLicense ...', this.productCodes)

        // const params = {
        //   olapOwner: this.olapdbOwner,
        //   olapIdentify: this.olapdbIdentify,
        //   authorizeTaskCount: this.licenseTaskCount,
        //   contactEmail: this.contactEmail,
        // }
        // console.log('applyLicense paras:', params)
        //
        // this.$axios
        //   .post('/olap/license/trial', params)
        //   .then((response) => {
        //     console.log('applyLicense response: ', response)
        //     if (response.data.code === 1) {
        //       this.$store.commit('updateOlapInfo')
        //     } else {
        //       console.log('applyLicense failed: ', response.data.msg)
        //     }
        //   })
        //   .catch((error) => {
        //     console.log('applyLicense failed: ', error)
        //   })

        // const params = {
        //   olapOwner: this.olapdbOwner,
        //   olapIdentify: this.olapdbIdentify,
        //   authorizeTaskCount: this.licenseTaskCount,
        //   contactEmail: this.contactEmail,
        // }

        const years = this.licensePeriod.charAt(0) === 'P' ? '5' : this.licensePeriod.charAt(0)
        const productCode = this.productCodes[this.licenseTaskCount + '_TASK_' + years + '_YEAR']
        let purchaseUrl = 'https://secure.2checkout.com/order/checkout.php?PRODS=' + productCode + '&QTY=1'
        if (this.olapdbOwner != null) {
          purchaseUrl += '&ADDITIONAL_OLAP_ORG_NAME=' + this.olapdbOwner
        }
        if (this.olapdbIdentify != null) {
          purchaseUrl += '&ADDITIONAL_OLAP_IDENTIFY_CODE=' + this.olapdbIdentify
        }
        window.open(purchaseUrl, '_blank')
      },
    },

    computed: {
      getPeriods () {
        return [
          this.$t('purchaseYear1'),
          this.$t('purchaseYear2'),
          this.$t('purchaseYear3'),
          this.$t('purchaseYear4'),
          this.$t('purchasePermanent'),
        ]
      },
      getTasks () {
        return [
          10,
          20,
          50,
          100,
          200,
          500,
          1000,
          2000,
          5000,
          10000,
        ]
      },
    },
  }
</script>
