<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />

    <v-text-field
      v-model="olapdbOwner"
      :label="$t('licenseOwner')"
      outlined
      dense
      :placeholder="$t('licenseOwner')"
      :hint="$t('licenseHint')"
    ></v-text-field>

    <v-text-field
      v-model="olapdbIdentify"
      :label="$t('licenseIdentify')"
      outlined
      dense
      :placeholder="$t('licenseIdentify')"
      :hint="$t('licenseHint')"
    ></v-text-field>

    <!--<v-text-field-->
      <!--v-model="licenseTaskCount"-->
      <!--:label="$t('licenseTaskCount')"-->
      <!--outlined-->
      <!--dense-->
      <!--:placeholder="$t('licenseHintTask')"-->
      <!--:hint="$t('licenseHintTask')"-->
    <!--&gt;</v-text-field>-->
    <v-select
      :items="getTasks"
      v-model="licenseTaskCount"
      :label="$t('licenseTaskCount')"
      outlined
      dense
      :placeholder="$t('licenseHintTask')"
      :hint="$t('licenseHintTask')"
    ></v-select>

    <base-text-field
      readonly
      :label="$t('licensePeriod')"
    />

    <v-text-field
      v-model="contactEmail"
      :label="$t('licenseEmail')"
      outlined
      dense
      :placeholder="$t('licenseEmail')"
      :hint="$t('licenseEmailHint')"
    ></v-text-field>

    <base-textarea
      class="mb-6"
      :label="$t('licenseInfo')"
    />

    <base-btn
      :color="!theme.isDark ? 'accent' : 'white'"
      outlined
      target="_blank"
      @click="applyTrialLicense"
    >
      {{$t('licenseSendMessage')}}
    </base-btn>
  </div>
</template>

<script>
  export default {
    name: 'BaseLicenseForm',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    props: {
      olapdbOwner: String,
      olapdbIdentify: String,
      licenseTaskCount: Number,
      contactEmail: String,

      subtitle: String,
      title: {
        type: String,
        default: 'MAIL US YOUR MESSAGE',
      },
    },

    methods: {
      applyTrialLicense () {
        this.dialog = false
        console.log('applyLicense ...', this.$refs)

        const params = {
          olapOwner: this.olapdbOwner,
          olapIdentify: this.olapdbIdentify,
          authorizeTaskCount: this.licenseTaskCount,
          contactEmail: this.contactEmail,
        }
        console.log('applyLicense paras:', params)

        this.$axios
          // .post('/olap/license/trial', params)
          .post('https://www.olapdb.com/olap/license/trial', params)
          .then((response) => {
            console.log('applyLicense response: ', response)
            if (response.data.code === 1) {
              this.$store.commit('updateOlapInfo')
            } else {
              console.log('applyLicense failed: ', response.data.msg)
            }
          })
          .catch((error) => {
            console.log('applyLicense failed: ', error)
          })
      },
    },

    computed: {
      getTasks () {
        return [
          10,
          20,
          50,
          100,
          200,
          500,
          1000,
          2000,
          5000,
          10000,
        ]
      },
    },
  }
</script>
