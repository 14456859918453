<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        :title="title"
        color="primary"
      >
        <slot />
      </base-info-card>

      <template v-for="({ icon, text, title: t }, i) in getBusiness">
        <base-avatar-card
          :key="i"
          :icon="icon"
          :outlined="false"
          :title="!dense ? t : undefined"
          color="transparent"
          horizontal
          space="0"
        >
          <!-- Do not use v-html for user -->
          <!-- provided values -->
          <div v-html="text" />
        </base-avatar-card>

        <v-divider
          v-if="i + 1 !== getBusiness.length"
          :key="`divider-${i}`"
          class="my-2"
        />
      </template>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessContact',

    props: {
      dark: Boolean,
      dense: Boolean,
      title: String,
    },

    computed: {
      getBusiness () {
        return [
          {
            icon: 'mdi-map-marker-outline',
            title: 'Address',
            text: 'Room 4-804, Lane 626, Chifeng Road<br>Hongkou District, Shanghai, China',
          },
          {
            icon: 'mdi-cellphone',
            title: '电话',
            text: this.$t('contactPhone') + ':<br>(+86)18918221910',
          },
          {
            icon: 'mdi-email',
            title: 'Email',
            text: 'info@olapdb.com<br>michaltina@hotmail.com',
          },
          {
            icon: 'mdi-wechat',
            title: 'Wechat',
            text: this.$t('wechat') + ': 18918221910<br>QQ: 1170743',
          },
        ]
      },
    },

    data: () => ({
    }),
  }
</script>
